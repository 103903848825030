<script>
export default {
    methods: {
        topFunction() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    },

    mounted() {

        let backtoTop = document.getElementById("back-to-top");

        if (backtoTop) {
            window.onscroll = function () {
                if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                    backtoTop.style.display = "block";
                } else {
                    backtoTop.style.display = "none";
                }
            };
        }
    },

};
</script>
<template>
    <footer class="custom-footer bg-dark py-5 position-relative">
        <b-container>
            <b-row>
                <b-col lg="4" class="mt-4">
                    <div>
                        <div>
                            <img src="@/assets/images/logo-light.png" alt="logo light" height="32">
                        </div>
                        <div class="mt-4 fs-13">
                            <p class="ff-secondary">인쇄분야 웹개발전문 회사<br>인쇄ERP/CRM, 인쇄MES/POP, 인쇄쇼핑몰, 맞춤형 웹개발</p>
                            <p class="ff-secondary mb-1">회사명 : 인터코디</p>
                            <p class="ff-secondary mb-1">서비스명 : 다온코드</p>
                            <p class="ff-secondary mb-1">EMAIL : louiskwon@gmailcom</p>
                            <p class="ff-secondary mb-1">PHONE : 02-6407-1001</p>
                            <p class="ff-secondary mb-1">MOBILE : 010-2982-4209</p>
                            <p class="ff-secondary mb-1">서울 중구 을지로 14길 7 성진빌딩 605호</p>
                        </div>
                    </div>
                </b-col>

                <b-col lg="7" class="ms-lg-auto">
                    <b-row>
                        <b-col sm="4" class="mt-4">
                            <h5 class="text-white mb-0">회사소개</h5>
                            <div class="text-muted mt-3">
                                <ul class="list-unstyled ff-secondary footer-list">
                                    <li>
                                        <router-link to="/pages/about">회사소개</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/pages/shop">인쇄쇼핑몰</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/pages/mes">MES/POP</router-link>
                                    </li>
                                    <li v-if="false">
                                        <router-link to="/pages/erp">ERP/CRM</router-link>
                                    </li>
                                </ul>
                            </div>
                        </b-col>
                        <b-col sm="4" class="mt-4">
                            <h5 class="text-white mb-0">상담문의</h5>
                            <div class="text-muted mt-3">
                                <ul class="list-unstyled ff-secondary footer-list">
                                    <li>
                                        <router-link to="/pages/contactus">인쇄쇼핑몰 제작문의</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/pages/contactus">MES/POP 개발문의</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/pages/contactus">ERP/CRM 개발문의</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/pages/contactus">운영/유지보수 상담</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/pages/contactus">기타 맞춤웹개발 상담</router-link>
                                    </li>
                                </ul>
                            </div>
                        </b-col>
                        <b-col sm="4" class="mt-4">
                            <h5 class="text-white mb-0">고객안내</h5>
                            <div class="text-muted mt-3">
                                <ul class="list-unstyled ff-secondary footer-list">
                                    <li>
                                        <router-link to="/pages/pricing">제작가격</router-link>
                                    </li>
                                    <li v-if="false">
                                        <router-link to="/pages/faqs">FAQ's</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/pages/contactus">개발 견적가 문의</router-link>
                                    </li>
                                </ul>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

            </b-row>

            <b-row class="text-center text-sm-start align-items-center mt-3">
                <b-col sm="6">
                    <div>
                        <p class="copy-rights mb-0 currentyear">2022 © 다온코드 ( DAONCODE.COM )
                        </p>
                    </div>
                </b-col>
                <b-col sm="6" v-if="false">
                    <div class="text-sm-end mt-3 mt-sm-0">
                        <ul class="list-inline mb-0 footer-social-link">
                            <li class="list-inline-item">
                                <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                    <div class="avatar-title rounded-circle">
                                        <i class="ri-facebook-fill"></i>
                                    </div>
                                </b-link>
                            </li>
                            <li class="list-inline-item">
                                <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                    <div class="avatar-title rounded-circle">
                                        <i class="ri-github-fill"></i>
                                    </div>
                                </b-link>
                            </li>
                            <li class="list-inline-item">
                                <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                    <div class="avatar-title rounded-circle">
                                        <i class="ri-linkedin-fill"></i>
                                    </div>
                                </b-link>
                            </li>
                            <li class="list-inline-item">
                                <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                    <div class="avatar-title rounded-circle">
                                        <i class="ri-google-fill"></i>
                                    </div>
                                </b-link>
                            </li>
                            <li class="list-inline-item">
                                <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                    <div class="avatar-title rounded-circle">
                                        <i class="ri-dribbble-line"></i>
                                    </div>
                                </b-link>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
    <b-button variant="info" @click="topFunction" class="btn-icon" id="back-to-top">
        <i class="ri-arrow-up-line"></i>
    </b-button>
</template>