<script>
import appConfig from "../../../app.config";
import NavMenu from "./inc/nav-menu.vue";
import Footer from "./inc/footer.vue";

export default {
    page: {
        title: "Starter",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {

        };
    },
    components: {
        NavMenu,
        Footer
    },
    methods: {
    },
    mounted() {
    },
};
</script>

<template>
    <div class="layout-wrapper landing">

        <nav-menu></nav-menu>

        <section class="section nft-hero" id="hero">
            <div class="bg-overlay"></div>
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8" sm="10">
                        <div class="text-center">
                            <h1 class="display-5 fw-medium mb-4 lh-base text-white">인쇄 쇼핑몰/ERP/MES 맞춤개발</h1>
                            <h1><span class="text-success">인쇄분야 시스템개발 전문회사</span></h1>
                            
                            <p class="lead text-white-50 lh-base mb-4 pb-2">대형 인쇄소에서의 다양한 개발경험으로 귀사의 오프라인 업무를 온라인업무로 바꿔드립니다.</p>

                            <div class="hstack gap-2 justify-content-center">
                                <router-link to="/pages/shop" class="btn btn-primary">인쇄쇼핑몰 안내<i
                                        class="ri-arrow-right-line align-middle ms-1"></i></router-link>
                                <router-link to="/pages/mes" class="btn btn-danger">인쇄MES 안내 <i
                                        class="ri-arrow-right-line align-middle ms-1"></i></router-link>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="section" id="skills">
            <b-container>
                <b-row class="justify-content-center">

                    <div class="text-center mt-lg-5 pt-5">
                            <h1 class="display-6 fw-semibold mb-3 lh-base">인쇄분야 맞춤개발 전문회사 <span class="text-success">다온코드</span>
                            </h1>
                            <p class="lead text-muted lh-base">인쇄쇼핑몰 인쇄 쇼핑몰/MES/ERP 맞춤개발, 인쇄시스템 운영 및 유지보수</p>

                            <div class="d-flex gap-2 justify-content-center mt-4">
                                <router-link to="/pages/contactus" class="btn btn-danger">개발 문의<i class="ri-eye-line align-middle ms-1"></i></router-link>
                            </div>
                        </div>

                        <div class="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                            <div class="demo-img-patten-top d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>
                            <div class="demo-img-patten-bottom d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>
                            <div class="carousel slide carousel-fade"><img src="@/assets/images/index/website_bundle.png" alt="" class="mb-3 pb-2 img-fluid"></div>
                        </div>

                    
                </b-row>
            </b-container> 
        </section> 

        <section class="section" id="service">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h2 class="mb-3 fw-semibold lh-base">대형인쇄소 개발자 출신의</h2>
                            <h1 class="mb-3 fw-semibold lh-base text-success">인쇄분야 웹개발전문 회사</h1>
                            <p class="text-muted">인쇄분야의 오랜 개발경험(10년이상)과 다양한 프로젝트 수행능력으로 귀사에 최적화된 시스템을 맞춤형으로 개발해드립니다.</p>
                            <p class="text-muted fs-13"><span class="text-info">신규개발, 연동개발, 기능확장, 커스트마이징 개발, 유지보수, 필요시 파견개발 등</span></p>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="g-4">
                    
                    <b-col lg="4">
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-body class="py-5 px-4">
                                <h5>인쇄쇼핑몰 전문개발</h5>
                                <p class="text-muted fs-13">신규개발, 연동개발, 기능확장, 커스트마이징 개발 등</p>
                                <router-link to="/pages/contactus" class="btn btn-info">인쇄쇼핑몰 개발안내</router-link>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4">
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-body class="py-5 px-4">
                                <h5>인쇄 MES/POP 맞춤개발</h5>
                                <p class="text-muted fs-13">신규개발, 연동개발, 기능확장, 커스트마이징 개발 등</p>
                                <router-link to="/pages/contactus" class="btn btn-info">MES/POP 개발안내</router-link>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4">
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-body class="py-5 px-4">
                                <h5 class="pb-2">인쇄 ERP/CRM 맞춤문의</h5>
                                <p class="text-muted fs-13">신규개발, 연동개발, 기능확장, 커스트마이징 개발 등</p>
                                <router-link to="/pages/contactus" class="btn btn-info">ERP/CRM 개발안내</router-link>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <Footer></Footer>

    </div>
</template>