<script>

export default {
    data() {
        return {
            path : this.$route.path
        }
    },
    computed : {
        is_mobile() {
            const isMobile = window.matchMedia("only screen and (max-width: 760px)")
            return isMobile.matches ? true : false
        },
    },
    mounted() {
        
        var navbar = document.getElementById("navbar");

        if(this.is_mobile) {
            navbar.classList.add("is-sticky");
        }
        else {
            window.addEventListener('scroll', function (ev) {
                ev.preventDefault();
                
                if (navbar) {
                    if (document.body.scrollTop >= 22 || document.documentElement.scrollTop >= 22) {
                        navbar.classList.add("is-sticky");
                    } else {
                        navbar.classList.remove("is-sticky");
                    }
                }
            });
        }
    },

};

</script>
<template>
    <nav class="navbar navbar-expand-lg navbar-landing navbar-light fixed-top" id="navbar">
        <b-container>
            <router-link class="navbar-brand" to="/">
                <img src="@/assets/images/logo-dark.png" class="card-logo card-logo-dark" alt="logo dark" height="32">
                <img src="@/assets/images/logo-light.png" class="card-logo card-logo-light" alt="logo light" height="32">
            </router-link>
            <button class="navbar-toggler py-0 fs-20 text-body" type="button" v-b-toggle.navbarSupportedContent>
                <i class="mdi mdi-menu"></i>
            </button>

            <b-collapse class="navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
                    <li class="nav-item">
                        <router-link to="/pages/about" class="nav-link fs-15" :class="{'active': $route.path === '/pages/about'  }">회사소개</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/pages/shop" class="nav-link fs-15" :class="{'active': $route.path === '/pages/shop'  }">다온샵</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/pages/mes" class="nav-link fs-15" :class="{'active': $route.path === '/pages/mes'  }">다온MES</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/pages/erp" class="nav-link fs-15" :class="{'active': $route.path === '/pages/erp'  }">ERP맞춤개발</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/pages/pricing" class="nav-link fs-15" :class="{'active': $route.path === '/pages/pricing'  }">제작가격</router-link>
                    </li>
                    <li class="nav-item" v-if="false">
                        <router-link to="/pages/faqs" class="nav-link fs-15" :class="{'active': $route.path === '/pages/faqs'  }">FAQ</router-link>
                    </li>
                </ul>

                <div class="">
                    <router-link to="/pages/contactus" class="btn btn-success" :class="{'active': $route.path === '/pages/contactus'  }">개발문의</router-link>
                </div>
            </b-collapse>

        </b-container>
    </nav>
</template>